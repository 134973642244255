<template>
  <div>
    <AppBar :title="$t('orderNo.title') + ' ' + order.id"/>
    <v-progress-linear v-if='loading' color='blue' class='progress' indeterminate></v-progress-linear>
    <v-card flat v-if='order' class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn @click='remove' :disabled='loading' color='primary' class='ml-2' depressed rounded>
          <v-icon dark>
            mdi-delete-forever
          </v-icon>
          {{ $t('clickAndCollectOrders.delete') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols='3' v-if='order.receiver'>
            <v-card flat>
              <v-card-title>
                {{ $t('customerDetails.title') }}
              </v-card-title>
              <v-card-text>
                <h4>{{ $t('customerDetails.FirstAndLastName') }}</h4>
                <p>{{ order.receiver.name }} {{ order.receiver.surname }}</p>
                <h4>{{ $t('customerDetails.Email') }}</h4>
                <p>{{ order.receiver.email }}</p>
                <h4>{{ $t('customerDetails.Phone') }}</h4>
                <p v-if='order.receiver.phone'>+{{ order.receiver.phone.countryCallingCode }}
                  {{ order.receiver.phone.number }}</p>
                <h4>{{ $t('customerDetails.Address') }}</h4>
                <p>{{ order.receiver.street }}, {{ order.receiver.zipCode }} {{ order.receiver.city }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols='3'>
            <v-card flat>
              <v-card-title>
                {{ $t('invoiceDetails.title') }}
              </v-card-title>
              <v-card-text>
                <h4>{{ $t('invoiceDetails.Name') }}</h4>
                <p>{{ order.accountingNote.name }}</p>
                <h4>{{ $t('invoiceDetails.VATNumber') }}</h4>
                <p>{{ order.accountingNote.vatNumber }}</p>
                <h4>{{ $t('invoiceDetails.Address') }}</h4>
                <p>{{ order.accountingNote.street }}, {{ order.accountingNote.zipCode }} {{ order.accountingNote.city
                  }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols='3'>
            <v-card flat>
              <v-card-title>
                {{ $t('cardDetails.title') }}
              </v-card-title>
              <v-card-text>
                <h4>{{ $t('cardDetails.selectedGallery') }}</h4>
                <p>{{ order.shoppingCenterName }}</p>
                <h4>{{ $t('cardDetails.orderAmount') }}</h4>
                <p>{{ order.chargeAmount }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Confirm ref='confirm'></Confirm>
  </div>
</template>
<script>
import Service from '@/services'
import { displayInfo } from '@/utils/helpers'
import Confirm from '../../components/dialogs/Confirm.vue'
export default {
  components: { Confirm },
  name: 'Order',
  data () {
    return {
      order: null,
      loading: false
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getOrder()
    }
  },
  computed: {},
  methods: {
    async getOrder () {
      this.loading = true

      try {
        const { data } = await Service.get(`/ClickCollect/${this.$route.params.id}`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async remove () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('orderModals.deleteOrder'), this.$t('orderModals.deleteOrderConfirm'))) {
        try {
          await Service.delete('ClickCollect', this.order.id)

          displayInfo(this.$t('orderModals.deleteClickAndCollectOrderSuccess'))
          this.$router.push({ path: '/clickcollect' })
        } catch (error) {}
      }

      this.loading = false
    }
  }
}
</script>
