<template>
<div v-if="order">
  <AppBar :title="$t('orderNo.title') + '' + order.businessId"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat class="rounded-xl pt-2 pb-4">
    <v-card-title>
      <v-chip label :color="order.state == 'Opłacone' ? 'green' : order.state == 'Anulowane' ? 'red' : ''">{{ order.state }}</v-chip>
      <v-spacer></v-spacer>
      <v-btn @click="downloadProvisionalInvoice" :disabled="loading" depressed rounded>
        <v-icon dark>
          mdi-download
        </v-icon>
        {{ this.$t('invoice.provisionalInvoice') }}
      </v-btn>
      <v-btn @click="downloadInvoice" :disabled="loading" class="ml-2" depressed rounded>
        <v-icon dark>
          mdi-download
        </v-icon>
        {{ this.$t('invoice.title') }}
      </v-btn>
      <v-btn @click="downloadAccountingNote" :disabled="loading" color="primary" class="ml-2" depressed rounded>
        <v-icon dark>
          mdi-download
        </v-icon>
        {{ this.$t('invoice.note') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
        <v-row class="mt-8">
          <v-col cols="3">
            <h4>{{ this.$t('date.dateOfOrder') }}</h4>
            <p>{{ order.created | dateFormat("DD.MM.YYYY HH:mm") }}</p>
            <h4>{{ this.$t('date.dateOfOrderCompletion') }}</h4>
            <p><span v-if="order.completionDate">{{ order.completionDate | dateFormat("DD.MM.YYYY HH:mm") }}</span><span v-else>-</span></p>
          </v-col>
          <v-col cols="2">
            <h4>{{ this.$t('orderNo.orderNumber') }}</h4>
            <p>{{ order.businessId }}</p>
              <h4>{{ this.$t('orderTotal.title') }}</h4>
            <p>{{ order.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</p>
          </v-col>
          <v-col cols="2">
            <h4>{{ this.$t('payment.paymentType') }}</h4>
            <p>{{ order.paymentMethod }}</p>
            <h4>{{ this.$t('shippingMethod.title') }}</h4>
            <p>{{ order.type }} - {{ order.shippingMethod.name }}</p>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="4" v-if="order.allowedActions">
            <h5 v-if="isAnyAllowedAction" class="mt-n4">{{ $t('statusManagement.title') }}</h5>
            <v-btn v-if="order.allowedActions.markAsPaid && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === managerRole)" @click="markAsPaid" :disabled="loading" class="my-2" color="success" depressed block rounded>{{ $t('statusButtons.markAsPaid') }}</v-btn>
            <v-btn v-if="order.allowedActions.assignCardTokens && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="assignTokens" :disabled="loading" class="my-2" color="primary" depressed block rounded>{{ $t('statusButtons.assignTokens') }}</v-btn>
            <v-btn v-if="order.allowedActions.generateActivationCodes && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" :disabled="loading" @click="generateActivationCodes" class="my-2" color="primary" depressed block rounded>{{ $t('statusButtons.generateActivationCodes') }}</v-btn>
            <v-btn v-if="order.allowedActions.generateActivationCodes && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" :disabled="loading" @click="unassignCardTokens" class="my-2" depressed block rounded>{{ $t('statusButtons.unassignTokens') }}</v-btn>
            <v-btn v-if="order.allowedActions.loadCards && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="loadCards" :disabled="loading" class="my-2" color="primary" depressed block rounded>{{ $t('statusButtons.loadCards') }}</v-btn>
            <v-btn v-if="order.allowedActions.sendActivationCodes && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="sendActivationCodes" :disabled="loading" class="my-2" color="primary" depressed block rounded>{{ $t('statusButtons.sendActivationCodes') }}</v-btn>
            <v-btn v-if="order.allowedActions.markAsSend && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="markAsSend" :disabled="loading" class="my-2" color="success" depressed block rounded>{{ $t('statusButtons.markAsSend') }}</v-btn>
            <v-btn v-if="order.allowedActions.complete && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="complete" :disabled="loading" class="my-2" color="success" depressed block rounded>{{ $t('statusButtons.complete') }}</v-btn>
            <v-btn v-if="order.allowedActions.cancel && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="cancel" :disabled="loading" class="my-2" depressed block rounded>{{ $t('statusButtons.cancel') }}</v-btn>
            <v-btn v-if="order.allowedActions.resendDetailsForCashier && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="resendDetailsForCashier" :disabled="loading" class="my-2" color="success" depressed block rounded>{{ $t('statusButtons.resendDetailsForCashier') }}</v-btn>
            <v-btn v-if="order.allowedActions.resendPickUpCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="resendPickUpCode" :disabled="loading" class="my-2" depressed block rounded>{{ $t('statusButtons.resendPickUpCode') }}</v-btn>
            <v-btn v-if="order.allowedActions.resendClickAndCollectCode && (userRoleId === adminRole || userRoleId === globalAdminRole || userRoleId === employeeRole)" @click="resendClickAndCollectCode" :disabled="loading" class="my-2" depressed block rounded>{{ $t('statusButtons.resendClickAndCollectCode') }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-card flat outlined>
              <v-card-title>
                {{ this.$t('order.title') }}
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="500px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t('name.title') }}</th>
                        <th class="text-left">{{ $t('token.title') }}</th>
                        <th class="text-right">{{ $t('amount.title') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(card, i) in order.giftCards" :key="i">
                        <td>{{ card.shoppingCenterName }}</td>
                        <td>{{ card.token }}</td>
                        <td class="text-right">{{ card.chargeAmount.toFixed(2) }} {{ getLocalCurrency }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="ma-4 ml-auto" style="width: 350px;">
                  <div class="d-flex justify-space-between my-1">
                    <div>{{ this.$t('totalCostOfDelivery.title') }}:</div>
                    <div class="font-weight-black">{{ order.shippingPrice ? order.shippingPrice.toFixed(2) : 0 }} {{ getLocalCurrency }}</div>
                  </div>
                  <div class="d-flex justify-space-between my-1">
                    <div>{{ this.$t('totalCardLoad.title') }}:</div>
                    <div class="font-weight-black">{{ (order.totalChargeAmount - order.shippingPrice).toFixed(2) }} {{ getLocalCurrency }}</div>
                  </div>
                  <div class="d-flex justify-space-between my-1">
                    <div>{{ this.$t('total.title') }}:</div>
                    <div class="font-weight-black">{{ order.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" v-if="order.receiver">
            <v-card flat>
              <v-card-title>{{ $t('customerDetails.title') }}</v-card-title>
              <v-card-text>
                <h4>{{ $t('customerDetails.FirstAndLastName') }}</h4>
                <p>{{ order.receiver.name }} {{ order.receiver.surname }}</p>
                <h4>{{ $t('customerDetails.Email') }}</h4>
                <p>{{ order.receiver.email }}</p>
                <h4>{{ $t('customerDetails.Phone') }}</h4>
                <p v-if="order.receiver.phone">+{{ order.receiver.phone.countryCallingCode }} {{ order.receiver.phone.number }}</p>
                <h4>{{ $t('customerDetails.Address') }}</h4>
                <p>{{ order.receiver.street }}, {{ order.receiver.zipCode }} {{ order.receiver.city }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card flat>
            <v-card-title>{{ $t('shippingDetails.title') }}</v-card-title>
              <v-card-text>
                <h4>{{ $t('shippingDetails.FirstAndLastName') }}</h4>
                <p>{{ order.shippingDetails.name }} {{ order.shippingDetails.surname }}</p>
                <h4>{{ $t('shippingDetails.Email') }}</h4>
                <p>{{ order.shippingDetails.email }}</p>
                <h4>{{ $t('shippingDetails.Phone') }}</h4>
                <p v-if="order.shippingDetails.phone">+{{ order.shippingDetails.phone.countryCallingCode }} {{ order.shippingDetails.phone.number }}</p>
                <h4>{{ $t('shippingDetails.Address') }}</h4>
                <p>{{ order.shippingDetails.street }}, {{ order.shippingDetails.zipCode }} {{ order.shippingDetails.city }}</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card flat>
              <v-card-title>{{ $t('invoiceDetails.title') }}</v-card-title>
              <v-card-text>
                <h4>{{ $t('invoiceDetails.Name') }}</h4>
                <p>{{ order.accountingNote.name }}</p>
                <h4>{{ $t('invoiceDetails.VATNumber') }}</h4>
                <p>{{ order.accountingNote.vatNumber }}</p>
                <h4>{{ $t('invoiceDetails.Address') }}</h4>
                <p>{{ order.accountingNote.street }}, {{ order.accountingNote.zipCode }} {{ order.accountingNote.city }}</p>
                <h4>{{ $t('invoiceDetails.AccountingNoteNumber') }}</h4>
                <p>{{ order.accountingNoteNumber }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-8" v-if="order.logs.length > 0">
          <v-col cols="8">
            <v-card flat>
              <v-card-title>{{ this.$t('changeHistory.title') }}</v-card-title>
              <v-card-text>
                <v-simple-table class="mt-2">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="width: 150px" class="text-center">{{ $t('changeHistory.date') }}</th>
                        <th style="width: 200px" class="text-center">{{ $t('changeHistory.user') }}</th>
                        <th class="text-left">{{ $t('changeHistory.name') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(log, i) in order.logs" :key="i">
                        <td class="text-center">{{ log.date | dateFormat("DD.MM.YYYY HH:mm") }}</td>
                        <td class="text-center">{{ log.actionPerformer }}</td>
                        <td>{{ log.actionName }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
  <AssignCardTokens ref="assignTokens" @refresh="getOrder"/>
  <Confirm ref="confirm"></Confirm>
  </div>
</template>
<script>
import { downloadFile, adminRoleId, managerRoleId, employeeRoleId, globalAdminId } from '@/utils/helpers'
import Service from '@/services'
import AssignCardTokens from './Dialogs/AssignCardTokens.vue'
import Confirm from '../../components/dialogs/Confirm.vue'
export default {
  components: { AssignCardTokens, Confirm },
  name: 'Order',
  data () {
    return {
      order: null,
      loading: false
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getOrder()
    }
  },
  computed: {
    isAnyAllowedAction () {
      return Object.values(this.order.allowedActions).some(a => a === true)
    },
    userRoleId () {
      return this.$store.getters.userRoleId
    },
    adminRole () {
      return adminRoleId
    },
    globalAdminRole () {
      return globalAdminId
    },
    employeeRole () {
      return employeeRoleId
    },
    managerRole () {
      return managerRoleId
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  methods: {
    async getOrder () {
      this.loading = true

      try {
        const { data } = await Service.get(`/Orders/${this.$route.params.id}`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    assignTokens () {
      this.$refs.assignTokens.show(this.order.giftCards, this.order.id)
    },
    async markAsPaid () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('statusButtons.markAsPaid'), this.$t('orderModals.markOrderAsPaid'))) {
        try {
          const { data } = await Service.post(`/Orders/${this.$route.params.id}/MarkAsPaid`)
          this.order = data
        } catch (error) {}
      }

      this.loading = false
    },
    async cancel () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('statusButtons.cancel'), this.$t('orderModals.cancel'))) {
        try {
          const { data } = await Service.post(`/Orders/${this.$route.params.id}/Cancel`)
          this.order = data
        } catch (error) {}
      }
      // else {
      // // cancel
      // }

      this.loading = false
    },
    async complete () {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('statusButtons.complete'), this.$t('orderModals.complete'))) {
        try {
          const { data } = await Service.post(`/Orders/${this.$route.params.id}/Complete`)
          this.order = data
        } catch (error) {}
      }

      this.loading = false
    },
    async sendActivationCodes () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/SendActivationCodes`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async markAsSend () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/MarkAsSent`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async loadCards () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/LoadCards`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async generateActivationCodes () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/GenerateActivationCodes`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async unassignCardTokens () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/RevertAssignedCardTokens`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async resendDetailsForCashier () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/ResendDetailsForCashier`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async resendPickUpCode () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/ResendPickUpCode`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async resendClickAndCollectCode () {
      this.loading = true

      try {
        const { data } = await Service.post(`/Orders/${this.$route.params.id}/ResendClickAndCollectCode`)
        this.order = data
      } catch (error) {}

      this.loading = false
    },
    async downloadAccountingNote () {
      this.loading = true

      try {
        const { data } = await Service.getFile(`/Orders/${this.$route.params.id}/AccountingNote`)

        downloadFile(`${this.$t('common.accountingNote')}-${this.order.businessId}.pdf`, data)
      } catch (error) {}

      this.loading = false
    },
    async downloadInvoice () {
      this.loading = true

      try {
        const { data } = await Service.getFile(`/Orders/${this.$route.params.id}/Invoice`)

        downloadFile(`${this.$t('common.invoice')}-${this.order.businessId}.pdf`, data)
      } catch (error) {}

      this.loading = false
    },
    async downloadProvisionalInvoice () {
      this.loading = true

      try {
        const { data } = await Service.getFile(`/Orders/${this.$route.params.id}/ProvisionalInvoice`)

        downloadFile(`${this.$t('common.proforma')}-${this.order.businessId}.pdf`, data)
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
